<template>
  <v-container class="media-library-view mt-5 px-10" fluid>
    <PageHeader
      header-text="Media Library"
      regular-button-text="NEW FOLDER"
      outlined-button-text="UPLOAD FILE"
      class="mb-7"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      :outlined-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="add_new_folder"
      @outlined-button-click="triggerUploadFile"
    >
      <template #subheader>
        <div>View all of the media you’ve imported</div>
      </template>
    </PageHeader>

    <div class="d-flex flex-row-reverse mb-5">
      <MediaControlPanel
        :sort_by_value="sort_by_value"
        :view_value="view_value"
        @change-view="view_value = $event"
        @change-sorting="sort_by_value = $event"
      />
    </div>

    <v-row>
      <v-col cols="12" sm="4">
        <MediaFolderStructure
          :folder_structure="mediaCollections"
          :selected_folder="selected_folder"
          :selected_folder_name="selected_folder_name"
          :folder-files="folderFiles"
          @select-folder="(folder, name) => select_folder(folder, name)"
        />
      </v-col>
      <v-col cols="12" sm="8">
        <ViewMedia
          :folder_content="folderFiles[selected_folder_name]"
          :selected_folder_name="selected_folder_name"
          :sort_by_value="sort_by_value"
          :view_value="view_value"
          :preview-files="previewFiles"
          @media-updated="reloadFolder"
          @folder-deleted="selected_folder_name = null; loadRoot()"
        />
      </v-col>
    </v-row>
    <v-dialog v-if="dialog" v-model="dialog" scrollable max-width="600px" content-class="new-folder-dialog">
      <v-card class="custom-field-popup-card pt-4">
        <v-card-title class="d-flex align-center justify-space-between px-5 pb-4">
          <div>
            Add New
            <span v-show="newFolderParent">Sub-Folder</span>
            <span v-show="!newFolderParent">Folder</span>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="p-4">
          <v-row class="pl-3">
            <v-col cols="12" class="pa-0">
              <validation-observer ref="addFolderForm">
                <CustomTextInput
                  v-model="newFolderName"
                  class="mt-6 mb-6"
                  header="Folder Name"
                  required
                  maxlength="32"
                />
              </validation-observer>
              <p v-show="newFolderParent">
                Parent Folder: <strong v-text="newFolderParent" />
                <v-icon color="red" style="cursor: pointer;" @click="clearAddParent">
                  mdi-close
                </v-icon>
              </p>
            </v-col>
            <v-col cols="12" class="pa-0 d-flex">
              <v-btn
                height="34px"
                class="elevation-0 custom-button custom-button--blue mt-2"
                width="150px"
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                height="34px"
                class="elevation-0 custom-button custom-button--blue mt-2 ml-auto"
                width="150px"
                @click="addFolder"
              >
                Add new folder
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <input
      ref="imageFile"
      type="file"
      accept=".jpg, .jpeg, .png, .gif, .pdf, .svg"
      style="display: none;"
      multiple
      @change="handleSelectedFile"
    >

    <file-upload
      ref="upload"
      v-model="draggedFiles"
      :multiple="true"
      :drop="true"
      :drop-directory="false"
      @input="handleDroppedFile"
    />

    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import MediaControlPanel from "@/views/MediaLibrary/components/MediaControlPanel";
import MediaFolderStructure from "@/views/MediaLibrary/components/MediaFolderStructure";
import ViewMedia from "@/views/MediaLibrary/components/ViewMedia";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import FileUpload from 'vue-upload-component';
import _ from 'lodash';

function Queue() {
  this.items = [];
}

Queue.prototype.enqueue = function (item) {
  this.items.push(item);
}

Queue.prototype.dequeue = function () {
  return this.items.shift();
}

Queue.prototype.hasItems = function () {
  return this.items.length > 0;
}

export default {
  name: "MediaLibraryView",
  metaInfo: {
    title: 'Media Library'
  },
  components: {
    CustomTextInput,
    PageHeader,
    MediaControlPanel,
    MediaFolderStructure,
    ViewMedia,
    FileUpload,
  },
  data() {
    return {
      dialog: false,
      sort_by_value: 'size',
      view_value: 'grid',
      selected_folder: null,
      selected_folder_name: null,
      mediaCollections: [],
      folderFiles: {},
      filesUploadQueue: new Queue(),
      previewFiles: [], // file that's uploading and will show preview mode
      queueUploadInterval: null,
      isUpload: false,
      isOnDrag: false,
      newFolderName: "",
      newFolderParent: "",
      draggedFiles: [],
    };
  },
  computed: {
    rootPath() {
      return this.$store.getters["user/account"].accountId + "/media-library/"
    },
    mediaPath() {
      return process.env.VUE_APP_REST_ADDRESS;
    },
  },
  created() {
    this.loadRoot();
  },
  mounted() {
    this.queueUploadInterval = setInterval(this.processUploadQueue, 2000)
  },
  beforeDestroy() {
    clearInterval(this.queueUploadInterval)
  },
  methods: {
    add_new_folder () {
      this.dialog = true
      this.newFolderName = ""
      this.newFolderParent = this.selected_folder_name
    },
    clearAddParent() {
      this.newFolderParent = ""
    },
    async reloadFolder() {
      const result = await this.$rest.media_library.get_collection_directory({
        directory: this.selected_folder_name
      });

      const folderFiles = [];
      (result.data || []).forEach(item => {
        // check if file 
        if (item.size) {
          folderFiles.push({...item})
        }
      });

      this.$set(this.folderFiles, this.selected_folder_name, folderFiles);
    },
    async select_folder(name) {
      this.selected_folder_name = name

      // load file
      if (!this.folderFiles[name]) {
        const folderFiles = [];
        const result = await this.$rest.media_library.get_collection_directory({
          directory: name
        });
        (result.data || []).forEach(item => {

          // check file or folder
          if (item.size) {
            // file
            folderFiles.push({...item})
          } else {
            // folder
            const itemWithoutRootPath = this.selected_folder_name + '/' + item.basename;
            this.mediaCollections.push(itemWithoutRootPath);
          }
        });

        // set shortage cache
        this.$set(this.folderFiles, name, folderFiles);
      }

      this.selected_folder = [...this.folderFiles[name]]
    },
    loadRoot() {
      this.$rest.media_library.get_collection().then(result => {
        const folders = [];

        result.data.forEach(item => {
          folders.push(item.basename);
        });

        this.$set(this, 'mediaCollections', folders);
      });
    },
    triggerUploadFile() {
      if (!this.selected_folder_name) {
        return this.$store.commit('snackbar/showMessage', {
          color: "error",
          content: "Please select a folder first."
        });
      }

      this.$refs.imageFile.click();
    },
    isImageFile(file) {
      return ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'image/svg+xml'].indexOf(file.type) >= 0;
    },
    handleSelectedFile(event) {
      Object.values(event.target.files).forEach(file => {
        //limit 3 mb
        if (file.size > 3*1024*1024) {
          this.$store.commit("snackbar/showMessage", {
            content: "The file size should be a less then 3MB",
            color: "error"
          });

          this.$refs.imageFile.value = '';
          return;
        }

        if (!this.isImageFile(file)) {
          this.$store.commit("snackbar/showMessage", {
            content: "We only accept the following file types: .jpg, .jpeg, .png, .gif, .pdf, .svg",
            color: "error"
          });

          this.$refs.imageFile.value = '';
          return;
        }

        const id = _.uniqueId('media-file-');

        // prepare the file for review
        this.addFileToPreview(file, id, this.selected_folder_name);

        // upload queue
        this.filesUploadQueue.enqueue({
          file,
          id: id,
          folder: this.selected_folder_name,
        });
      });
    },
    async uploadFileAPI(file, folder) {
      try {
        const formData = new FormData();
        formData.append("mediaFile", file);
        formData.append("directory", folder);

        const result = await this.$rest.media_library.post_resource(formData)
        const newFile = result.data[0];
        // add new added file into a correct folder
        setTimeout(() => {
          this.folderFiles[folder].push({...newFile});
        }, 500); // we have to wait a little bit for the BE to sync the file??

        return true;
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          const firstError = errorResponse.errors?.[0] ?? null;

          if (firstError) {
            return this.$store.commit('snackbar/showMessage', {
              'content': firstError.error,
              'color': 'error',
            });
          }
        }

        return this.$store.commit('snackbar/showMessage', {
          'content': 'Error while trying to upload the file, please try again.',
          'color': 'error',
        });
      } finally {
        this.$refs.imageFile.value = '';
      }
    },
    /**
     * Using the FileReader to transform a File to BASE64 for preview
     *
     * @param file
     * @param fileId
     * @param folder
     */
    addFileToPreview(file, fileId, folder) {
      const reader = new FileReader();
      reader.onload = () => {
        this.previewFiles.push({
          id: fileId,
          result: reader.result,
          folder: folder,
          name: file.name,
          size: file.size,
        });
      }

      reader.readAsDataURL(file);
    },
    async processUploadQueue() {
      if (!this.filesUploadQueue.hasItems()) {
        return;
      }
      if (this.isUpload) {
        return;
      }

      const file = this.filesUploadQueue.dequeue();
      await this.uploadFileAPI(file.file, file.folder);

      // remove from preview
      this.previewFiles.splice(
        this.previewFiles.findIndex(item => item.id === file.id),
        1
      );
    },
    handleDroppedFile() {
      if (!this.selected_folder_name) {
        this.draggedFiles = [];

        return this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: "Select a folder to upload first",
        });
      }

      this.draggedFiles.forEach((fileInfo) => {
        const file = fileInfo.file;
        if (!this.isImageFile(file)) {
          return;
        }

        const id = _.uniqueId('media-file-');

        // prepare the file for review
        this.addFileToPreview(file, id, this.selected_folder_name);

        // upload queue
        this.filesUploadQueue.enqueue({
          file,
          id: id,
          folder: this.selected_folder_name,
        });
      });

      // added all to queue => clear stuff
      this.draggedFiles = [];
    },
    async addFolder() {
      if (!(await this.$refs.addFolderForm.validate())) {
        return;
      }

      const folderNameToBeCreated = this.newFolderParent
          ? this.newFolderParent + "/" + this.newFolderName
          : this.newFolderName

      try {
        const formData = new FormData();
        formData.append("directory", folderNameToBeCreated);
        await this.$rest.media_library.post_resource_directory(formData)

        this.mediaCollections.push(folderNameToBeCreated);

        this.$store.commit("snackbar/showMessage", {
          color: "success",
          content: "Folder created",
        });

        this.dialog = false;

        // select folder after created
        this.select_folder(folderNameToBeCreated);
      } catch (e) {
        this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: "Failed to create new folder",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.media-library-view {
  .v-dialog.v-dialog--active {
    min-height: 90%;
  }

  label.btn {
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: .6;
    text-align: center;
    background: #000;
  }

  .drop-active h3 {
    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
  }
}

.drag-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .4);
  z-index: 15;
}
.drag-message .drag-message-info {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 2px #000000 dashed;
  padding: 50px;
  user-select: none;
}

.new-folder-dialog {
  height: 250px;
}
</style>
